<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <b-skeleton-img></b-skeleton-img> -->
    <parallax fixed style="height:100vh;">
    <Start/>
    </parallax>
    <b-container>
    <div class="spacer"/>
    <b-card id="about">
    <h2>{{ $t('menu.about') }}</h2>
    <About/>
    </b-card>

    <div class="spacer"/>
    <b-card id="booking">
    <h2>{{ $t('menu.booking') }}</h2>
    <PickDate button="true"/>
    </b-card>

    <div class="spacer"/>
    <b-card id="facilities">
    <h2>{{ $t('menu.facilities') }}</h2>
    <Facilities/>
    </b-card>

    <div class="spacer"/>
    <b-card id="location">
    <h2>{{ $t('menu.location') }}</h2>
    <Location/>
    </b-card>

    <div class="spacer"/>
    <b-card id="contact">
    <h2>{{ $t('menu.contact') }}</h2>
    <Contact/>
    </b-card>
    </b-container>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <br>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
import PickDate from '@/components/PickDate.vue'
import Facilities from '@/components/Facilities.vue'
import Location from '@/components/Location.vue'
import Contact from '@/components/Contact.vue'
import About from '@/components/About.vue'
import Start from '@/components/Start.vue'
import Parallax from 'vue-parallaxy'

export default {
  name: 'Home',
  components: {
    PickDate,
    Start,
    Facilities,
    Parallax,
    Location,
    Contact,
    About
  }
}
</script>

<style scoped lang="scss">

h2 {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.card-body {
  padding: 2rem;
}

</style>
