<template>
  <b-container>
    <b-row no-gutters>
      <b-col cols="12" class="mb-4 d-flex align-items-center h-100 mh-100">
        <b-carousel
          id="carousel-1"
          :interval="5000"
          fade
          indicators
          controls
          img-center
          background="#fcf9e9"
          style="text-shadow: 1px 1px 2px #333; border-radius: 8px; width: 100%;"
        >
          <b-carousel-slide v-for="image in $store.state.frontImages.facilities" :key="image">
            <template #img>
              <img 
                :src="image" 
                class="img-fluid w-100 d-inline-block h-100 mh-100"
              >
            </template>
          </b-carousel-slide>
        
        </b-carousel>
      </b-col>
      <b-col cols="12">
        <b-card-group columns>
          <b-card 
           no-body
           v-for="(item, index) in $t('facilities')" :key="index"
           class="mb-0 bt-0"
          >
            <ul class="mb-2 mb-md-3">
              <li>{{ item }}</li>
            </ul>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'Facilities',
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">

.img-fluid {
  width: 100% !important;
  height: 600px !important;
  object-position: center;
  object-fit: cover;
}

.card {
  border: none;
}

.card-columns {
        column-count: 1;
    }

@media (min-width: 992px) {
    .card-columns {
        column-count: 2;
    }
}
</style>
