<template>
  <b-navbar toggleable="lg"  style="background-color: #dfc620" sticky> <!-- type="light" variant="warning" -->
    <b-container>
      <b-navbar-brand to="/" @click="scrollTo('home')">La Solitude</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- <b-nav-item to="/" @click="scrollTo('home')">{{ $t('menu.home') }}</b-nav-item> -->
          <b-nav-item to="/" @click="scrollTo('about')">{{ $t('menu.about') }}</b-nav-item>
          <b-nav-item to="/" @click="scrollTo('booking')">{{ $t('menu.booking') }}</b-nav-item>
          <b-nav-item to="/" @click="scrollTo('facilities')">{{ $t('menu.facilities') }}</b-nav-item>
          <b-nav-item to="/" @click="scrollTo('location')">{{ $t('menu.location') }}</b-nav-item>
          <b-nav-item to="/" @click="scrollTo('contact')">{{ $t('menu.contact') }}</b-nav-item>
          <!-- <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
          </b-nav-form> -->

          <b-nav-item-dropdown text="Language" right>
            <b-dropdown-item @click="switchLocale('de')"><country-flag country='de' size='normal'/> Deutsch</b-dropdown-item>
            <b-dropdown-item @click="switchLocale('en')"><country-flag country='gb' size='normal'/> English</b-dropdown-item>
            <b-dropdown-item @click="switchLocale('fr')"><country-flag country='fr' size='normal'/> Français</b-dropdown-item>
            <b-dropdown-item @click="switchLocale('nl')"><country-flag country='nl' size='normal'/> Nederlands</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    scrollTo: function(id) {
      const elmnt = document.getElementById(id);
      this.$smoothScroll({
        scrollTo: elmnt,
        duration: 1000, 
        offset: -80,
        updateHistory: false
      }) 
    },
    switchLocale: function(locale){
      this.$i18n.locale = locale
    }
  },
}
</script>

<style scoped lang="scss">

</style>
