<template>
  <b-container>
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11683.869277604304!2d2.089584240912164!3d42.936815825764796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12afb924874ead91%3A0x817f1d2c04add661!2sCapis%2C%2011260%20Saint-Jean-de-Paracol%2C%20Frankreich!5e0!3m2!1sde!2sde!4v1609083967935!5m2!1sde!2sde" width="100%" height="350" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>-->
    <p v-for="(item, index) in $t('location.location')" :key="index">{{ item }}</p>
    <b-carousel
      id="carousel-1"
      :interval="5000"
      fade
      indicators
      controls
      background="#ababab"
      img-center
      style="text-shadow: 1px 1px 2px #333;"
      class="mt-4 mb-4"
    >
      <b-carousel-slide v-for="image in $store.state.frontImages.location" :key="image">
        <template #img>
          <img
            :src="image" 
            class="img-fluid w-100 d-block"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
    <h3>{{ $t('location.surroundings.surroundings') }}</h3>
    <ul>
      <li v-for="(item, index) in $t('location.surroundings.details')" :key="index">{{ item }}</li>
    </ul>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
.img-fluid {
  width: 100% !important;
  height: 600px !important;
  object-position: center;
  object-fit: cover;
}

</style>
