//index.js
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: {},
    form: {
      name: '',
      lastname: '',
      age: '',
      guests: [],
      email: '',
      tel: '',
      adress: {
        street: '',
        postal: '',
        city: '',
        country: ''
      },
      note: ''
    },
    calendar: {
      pickedRange: {
        start: new Date(),
        end: (new Date()).setDate(new Date() +1),
        locale: {
          start: new Date(),
          end: (new Date()).setDate(new Date() +1),
        }
      },
      minRange: 3,
      disabledDates: [
        {
          start: null,
          end: new Date()
        }
      ],
      booked: {
        full: [],
        upper: [],
        lower: []
      },
    },
    persons: {
      // adults: 2,
      // children: 0,
      total: 2,
      max: {
        full: 7,
        upper: 4,
        lower: 2,
        mixed: 2
      },
      min: {
        adults: 1,
        children: 0
      }
    },
    booking: {
      part: 'upper',
      state: 0,
      editing: 0,
      price: NaN
    },
    houserules: {
      de: [],
      en: [],
      nl: [],
      fr: []
    },
    service: false,
    serviceprice: 0,
    frontImages: {
      background: [],
      location: [],
      facilities: []
    }
  },

  getters: {
    errors(state) {
      return state.errors;
    }
  },

  mutations: {
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    setRange(state, range) {
      // const removeOffset = function(day) {
      //   new Date(day).setMinutes(day.getMinutes() - day.getTimezoneOffset());
      //   return day
      // }
      // state.calendar.pickedRange.start = new Date(removeOffset(range.start))
      // state.calendar.pickedRange.end = new Date(removeOffset(range.end))

      state.calendar.pickedRange.locale.start = new Date(range.start)
      state.calendar.pickedRange.locale.end = new Date(range.end)

      let toUTCDay = (day) => {
        day.setMinutes(day.getMinutes() - day.getTimezoneOffset())
        let date = new Date();
        date.setUTCFullYear(day.getUTCFullYear())
        date.setUTCMonth(day.getUTCMonth())
        date.setUTCDate(day.getUTCDate())
        date.setUTCHours(0)
        date.setUTCMinutes(0)
        date.setUTCSeconds(0)
        date.setUTCMilliseconds(0)
        return date
      }

      state.calendar.pickedRange.start = toUTCDay(range.start)
      state.calendar.pickedRange.end = toUTCDay(range.end)

      // state.calendar.pickedRange.start = new Date(range.start)
      // state.calendar.pickedRange.end = new Date(range.end)
    },
    setForm(state, newForm) {
      state.form = newForm
    },
    setBooked(state, newBooked) {
      state.calendar.booked = newBooked
    },
    // setAdults(state, value) {
    //   state.persons.adults = value
    // },
    // setChildren(state, value) {
    //   state.persons.children = value
    // },
    setTotalPersons(state, value) {
      state.persons.total = value
    },
    setSelectedRoom(state, room){
      state.booking.part = room
    },
    setBookingState(state, bookingstate){
      state.booking.state = bookingstate
    },
    setBookingPrice(state, newprice){
      state.booking.price = Number(newprice)
    },
    setBookingEditing(state, edit){
      state.booking.editing = edit
    },
    setHouserules(state, houserules){
      state.houserules = houserules
    },
    setService(state, service){
      state.service = service
    },
    setServiceprice(state, serviceprice){
      state.serviceprice = serviceprice
    },
    setFrontImages(state, images){
      state.frontImages = images
    }
  },

  actions: {
    setErrors({ commit }, errors) {
      commit("SET_ERRORS", errors);
    }
  }
});
