 
<template>
  <div class="datepicker">
    <b-container>
      <b-row class="d-flex justify-content-center">
        <div class="w-100"></div>
        <b-col cols="auto" md="6" lg="8" xl="9" order="1">
          <v-date-picker v-if="edit"
          :attributes="attributes"
          mode="range"
          :disabled-dates="[...$store.state.calendar.disabledDates, ...disabledDates]"
          :timezone="timezone"
          :columns="$screens({ default: 1, lg: 2, xl: 3 })"
          :step="1"
          :locale="locale"
          is-range
          is-inline
          v-model="range"
          :value="{start: $store.state.calendar.pickedRange.start, end: $store.state.calendar.pickedRange.end}"
          @input="updatePickedRange($event)" />

          <v-calendar v-if="!edit"
          :attributes="attributesno"
          mode="range"
          :disabled-dates="[...$store.state.calendar.disabledDates, ...disabledDates]"
          :timezone="timezone"
          :columns="$screens({ default: 1, lg: 2, xl: 3 })"
          :step="1"
          :locale="locale"
          is-range
          is-inline />
        </b-col>
        
        <b-col cols="auto" md="6" lg="4" xl="3" order="3" order-md="2" class="light-border">
          <!--<h3>{{ $t('booking.persons') }}</h3>-->
          <!-- <b-form inline>
            <label class="sr-only" for="inline-form-input-username">{{ $t('booking.adults') }}</label>
            <b-input-group size="md" :prepend="`${$t('booking.adults')}`" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-spinbutton id="adults" type="number" :min="$store.state.persons.min.adults" :max="maxAdults" v-model="adults" numbe1280pxr></b-form-spinbutton>
            </b-input-group>
          </b-form>
          <br>
          <b-form inline>
            <label class="sr-only" for="inline-form-input-username">{{ $t('booking.children') }}</label>
            <b-input-group size="md" :prepend="`${$t('booking.children')}`" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-spinbutton id="children" type="number" :min="$store.state.persons.min.children" :max="maxChildren" v-model="children" number></b-form-spinbutton>
            </b-input-group>
          </b-form> -->
          <br>
          <b-form inline>
            <label class="sr-only" for="inline-form-input-username">{{ $t('booking.persons') }}</label>
            <b-input-group size="md" :prepend="`${$t('booking.persons')}`" class="mb-2 mr-sm-2 mb-sm-0 stretch-full">
              <b-form-spinbutton id="persons" type="number" :min="$store.state.persons.min.adults" :max="$store.state.persons.max.full" v-model="totalPersons" :disabled="!edit" number></b-form-spinbutton>
            </b-input-group>
          </b-form>
          <br>
          <!--<b-form-select v-model="housePart" :options="optionsRoom"></b-form-select> @input="setMax"-->
          <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0 stretch-full"
            v-model="optionsRoom"
            disabled
            style="background-color: white;"
          ></b-form-input>
          <br>
          
          <h4 v-if="!price || disableButton || checkingPrice"> <!-- class="dot-flashing" style="margin-left: 16px;" -->
            <b-skeleton animation="wave" width="50%" class="h-100"></b-skeleton>
          </h4>
          <h4 v-else>{{ formatPrice(price) }}</h4>
          <br>
          <b-button v-if="edit" :disabled="disableButton || checkingPrice || preventBooking" @click="startBooking" class="stretch-full mb-2" variant="success" right>{{ $t('buttons.nextbooking') }}</b-button>
          <b-button v-if="!edit" variant="info" class="stretch-full mb-2" @click="editDates" right>Bearbeiten</b-button>
        </b-col>
        <b-col cols="12" order="2" order-md="3" class="mb-3 mb-sm-2 mb-md-0">
          <!-- <transition name="fade"> -->
          <div :class="{'active':messageactive}">
            <p class="message">{{ message }}</p>
          </div>
          <!-- </transition> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import _ from 'lodash'
import {formatPrice} from './mixins/formatPrice'
export default {
  name: 'PickDate',
  mixins: [formatPrice],
  props: ['button'],
  data() {
    return {
      style: {
        today: {
          key: 'today',
          highlight: {
            color: 'green',
            backgroundColor: 'green',
            fillMode: 'light',
          },
          dates: new Date(),
          // popover: {
          //   label: "Heute"
          // }
        }
      },
      range: {
        start: new Date(),
        end: new Date()
      },
      timezone: '', // 'UTC'
      selectedRoom: 'full',
      pickedDateRange: this.pickedDays(),
      checkingPrice: false,
      message: "",
      messageactive: false,
      preventBooking: true
    }
  },
  methods: {
    addDays: Date.prototype.addDays = function(days) {
      this.setDate(this.getDate() + parseInt(days));
      return this
    },
    // setMax: function(){
    //   let max = this.$store.state.persons.max['full']
    //   if (max < Number(this.adults) + Number(this.children)) {
    //     this.children = 0
    //     this.adults = 2
    //   }
    // },
    updatePickedRange: function(event){
      this.$store.commit('setRange', event)

      this.pickedDateRange = this.pickedDays()
    },
    pickedDays: function(){
      const getDaysBetweenDates = (startDate, endDate) => {
        let days = 0
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < endDate) {
          days += 1
          theDate.setDate(theDate.getDate() + 1)
        }
        return days
      }

      return getDaysBetweenDates(this.$store.state.calendar.pickedRange.start, this.$store.state.calendar.pickedRange.end)
    },
    startBooking: function(){
      if (this.$store.state.booking.editing == 1){
        this.$store.commit('setBookingEditing', 0)
      }
      else{
        this.$store.commit('setBookingState', 0)
        this.$router.push('booking')
      }
    },
    getPrice() {
      let form = {
        from: this.$store.state.calendar.pickedRange.start,
        to: this.$store.state.calendar.pickedRange.end,
        persons: this.totalPersons,
        // persons: {
        //   // adults: this.adults,
        //   // children: this.children
        //   total: this.totalPersons
        // },
        part: this.housePart
      }
      
      this.$axios
      .post('/cost.php', form)
      .then(response => {
        this.$store.commit('setBookingPrice', Number(response.data));
        this.checkingPrice = false;
      })
      .catch(error => {
        console.log(error);
        this.checkingPrice = false;
      })
    },
    debouncePrice: _.debounce(function() {
      this.getPrice();
    }, 2000),
    checkPrice() {
      this.checkingPrice = true;
      this.debouncePrice();
    },
    editDates() {
      this.$store.commit('setBookingEditing', 1);
    },
    setMessage(x){
      if (x==''){
        this.messageactive = false
      }
      else{
        this.message = x
        this.messageactive = true
      }
      
    }
  },
  computed: {
    attributes() {
      //return [this.style.today, {...this.style.upper, dates: this.$store.state.calendar.booked.upper}, {...this.style.lower, dates: this.$store.state.calendar.booked.lower}]
      return [this.style.today]
    },
    attributesno() {
      //return [this.style.today, {...this.style.upper, dates: this.$store.state.calendar.booked.upper}, {...this.style.lower, dates: this.$store.state.calendar.booked.lower}]
      return [this.style.today, {highlight: true, dates: this.$store.state.calendar.pickedRange}]
    },
    // timezone(){
    //   return 'UTC'
    // },
    locale(){
      switch(this.$i18n.locale){
        case "de":
          return "de-DE"
        case "en":
          return "en-GB"
        case "nl":
          return "nl-NL"
        case "fr":
          return "fr-FR"
      }
      return "en-GB"
    },
    // adults: {
    //   get () {
    //     return this.$store.state.persons.adults
    //   },
    //   set (value) {
    //     this.$store.commit('setAdults', value)
    //   }
    // },
    // children: {
    //   get () {
    //     return this.$store.state.persons.children
    //   },
    //   set (value) {
    //     this.$store.commit('setChildren', value)
    //   }
    // },
    totalPersons: {
      get () {
        return this.$store.state.persons.total
      },
      set (value) {
        this.$store.commit('setTotalPersons', value)
      }
    },
    housePart: {
      get () {
        return this.$store.state.booking.part
      },
      set (value) {
        this.$store.commit('setSelectedRoom', value)
      }
    },
    disabledDates() {
      if (this.housePart == 'upper'){
        return [...this.$store.state.calendar.booked.full, ...this.$store.state.calendar.booked.upper]
      }
      else {
        return [...this.$store.state.calendar.booked.full, ...this.$store.state.calendar.booked.upper, ...this.$store.state.calendar.booked.lower]
      }
    },
    // maxAdults() {
    //   let max = this.$store.state.persons.max['full']
    //   return max - this.children
    // },
    // maxChildren() {
    //   let max = this.$store.state.persons.max['full'] //this.$store.state.booking.part
    //   return max - this.adults
    // },
    littleRange() {
      if (this.pickedDateRange < this.$store.state.calendar.minRange){
        return true
      }
      return false
    },
    price() {
      if (isNaN(this.$store.state.booking.price)){
        return false
      }
      return this.$store.state.booking.price
    },
    disableButton() {
      if (this.littleRange){
        this.setMessage(this.$t('booking.warning.range', {min: this.$store.state.calendar.minRange + 1}))
        return true
      }
      // let children = this.children
      // let adults = this.adults

      // if (children === "" || adults === ""){
      //   return true
      // }

      // children = Number(children)
      // adults = Number(adults)

      // if (isNaN(children) || isNaN(adults)){
      //   return true
      // }
      // if (!Number.isInteger(adults) || !Number.isInteger(children)){
      //   return true
      // }
      // if (children < this.$store.state.persons.min.children || adults < this.$store.state.persons.min.adults){
      //   return true
      // }
      // if (children + adults > this.$store.state.persons.max['full']){
      //   return true
      // }

      let total = this.totalPersons

      if (total === ""){
        return true
      }

      total = Number(total)

      if (isNaN(total)){
        return true
      }
      if (!Number.isInteger(total)){
        return true
      }
      if (total < this.$store.state.persons.min.adults){
        this.setMessage(this.$t('booking.warning.peoplemin', {min: this.$store.state.persons.min.adults}))
        return true
      }
      if (total > this.$store.state.persons.max.full){
        this.setMessage(this.$t('booking.warning.peoplemax', {min: this.$store.state.persons.max.full}))
        return true
      }

      const getDatesBetweenDates = (startDate, endDate) => {
        let dates = []
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate <= endDate) {
          dates = [...dates, new Date(theDate)]
          theDate.setDate(theDate.getDate() + 1)
        }
        return dates
      }

      let pickedDates = getDatesBetweenDates(this.$store.state.calendar.pickedRange.start, this.$store.state.calendar.pickedRange.end)

      for (let x of pickedDates){
        for (let y of this.disabledDates){
          if (x.toISOString() === y.toISOString()){
            return true
          }
        }
      }

      if (this.edit){
        this.checkPrice()
      }
      
      this.setMessage("")
      return false
    },
    optionsRoom() {
      // const getDatesBetweenDates = (startDate, endDate) => {
      //   let dates = []
      //   //to avoid modifying the original date
      //   const theDate = new Date(startDate)
      //   while (theDate <= endDate) {
      //     dates = [...dates, new Date(theDate)]
      //     theDate.setDate(theDate.getDate() + 1)
      //   }
      //   return dates
      // }

      // let pickedDates = getDatesBetweenDates(this.$store.state.calendar.pickedRange.start, this.$store.state.calendar.pickedRange.end)

      // let full = false, upper = false //, lower = false

      // for (let x of pickedDates){
      //   for (let y of this.$store.state.calendar.booked.upper){
      //     if (x.toISOString() === y.toISOString()){
      //       full = true
      //       upper = true
      //     }
      //   }
      //   for (let y of this.$store.state.calendar.booked.lower){
      //     if (x.toISOString() === y.toISOString()){
      //       full = true
      //       lower = true
      //     }
      //   }
      // }

      // let mixed = null

      let total = this.totalPersons

      // let children = this.children
      // let adults = this.adults

      // children = Number(children)
      // adults = Number(adults)

      total = Number(total)

      // if (children + adults > this.$store.state.persons.max['upper']){
      //   upper = true
      //   this.$store.commit('setSelectedRoom', 'full')
      // }

      if (total > this.$store.state.persons.max['upper']){
        // upper = true
        this.$store.commit('setSelectedRoom', 'full')
        return this.$t('part.full')
      }
      else {
        // full = true
        this.$store.commit('setSelectedRoom', 'upper')
        return this.$t('part.upper')
      }

      // if (!full){
      //   this.$store.commit('setSelectedRoom', 'full')
      // }
      // else if (!upper){
      //   this.$store.commit('setSelectedRoom', 'upper')
      // }
      // else if (!lower){
      //   this.$store.commit('setSelectedRoom', 'lower')
      // }
      // else {
      //   this.$store.commit('setSelectedRoom', 'mixed')
      //   mixed = {value: 'mixed', text: 'Haupthaus/Appartement'}
      // }

      // let options = [
      //   { value: 'upper', text: this.$t('part.upper'), disabled: upper },
      //   { value: 'full', text: this.$t('part.full'), disabled: full },
      //   // { value: 'lower', text: 'Appartement (2 Pers.)', disabled: lower }
      // ]

      // // if (mixed != null) {
      // //   options = [...options, mixed]
      // // }

      // return options
    },
    edit() {
      return this.button || this.$store.state.booking.editing
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#top {
  margin-top: 2rem;
}

.light-border {
  border: 1px solid #cbd5e0 !important;
  border-radius: 8px;
  padding: 0.01em 16px;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}




.message{
  text-align: center;
  font-weight: bold;
  color: #555;
  margin-top: 1rem;
  font-size: 1rem;


  opacity: 0;
  height: 0;
  transition: opacity 0.3s, height 0.3s 0.1s;
}

.active .message{
  opacity: 1;
  height: 1rem;
  transition: opacity 0.3s 0.1s, height 0.3s;
}

.input-group {
  width: 100%
}

.multiline {
  white-space: pre-wrap;
}


</style>
