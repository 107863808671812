import Vue from 'vue'

import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  //componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  screens: {
    "sm": "576px",  // (min-width: 640px)
    "md": "768px",  // (min-width: 768px)
    "lg": "992px", // (min-width: 1024px)
    "xl": "1200px"  // (min-width: 1280px)
  }
  
});