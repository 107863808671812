<template>
  <b-container>
    <b-row>
      <b-col cols="12" md="6" class="mb-4 mb-md-0">
        <b-card class="h-100">
          <p v-for="(item, index) in $t('contact.details')" :key="index">{{ item }}</p>
        </b-card>
      </b-col>
      
      <b-col cols="12" md="6">
        <b-form @submit.prevent="startCaptchaSend" v-if="!sent">

          <b-form-input
            id="name"
            v-model="form.name"
            required
            trim
            :placeholder="$t('contact.name')"
          ></b-form-input>
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            required
            trim
            :placeholder="$t('contact.email')"
          ></b-form-input>
          <b-form-textarea
            id="message"
            v-model="form.message"
            :placeholder="$t('contact.message')"
            required
            trim
            rows="3"
          ></b-form-textarea>
          <vue-hcaptcha
            :key="componentKey"
            ref="invisibleHcaptcha"
            sitekey="a82ff4bd-e7a2-40ad-893f-6147c8554bb3"
            @verify="onVerify"
            @expired="onExpire"
            @error="onError"
          />
          <b-button type="submit" variant="success" :disabled="!notEmptyFields">{{ $t('buttons.send') }}</b-button> <!-- !captcha.verified ||  || !captcha.validated -->
        </b-form>
        <b-alert variant="success" show v-else style="height:100%;" class="d-flex align-items-center justify-content-center">
          <p>Vielen Dank für Ihre Nachricht!</p>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'; // for production
// import VueHcaptcha from '../../../src/main'; // for development

  export default {
    components: {
      VueHcaptcha
    },
    data() {
      return {
        form: {
          name: "",
          email: "",
          message: ""
        },
        captcha: {
          verified: false,
          expired: false,
          token: null,
          eKey: null,
          error: null,
          validated: false,
        },
        sent: false,
        componentKey: 0,
      }
    },
    methods: {
      startCaptchaSend(){
        this.$refs.invisibleHcaptcha.execute();
      },
      sendMessage: function(){
        alert("sendig message")

        let form = this.form
        form.token = this.captcha.token
      
        this.$axios
        .post('/message.php', form)
        .then(response => {
          if (response.data.success){
            this.captcha.validated = true;
            this.sent = true;
          }
          else{
            this.captcha.validated = false;
          }
        })
        .catch(error => {
          console.log(error)
          this.captcha.validated = false;
        })
      },
      onVerify(token, ekey) {
        this.captcha.verified = true;
        this.captcha.token = token;
        this.captcha.eKey = ekey;
        //console.log(`Callback token: ${token}, ekey: ${ekey}`);
        this.sendMessage;
      },
      onExpire() {
        this.captcha.verified = false;
        this.captcha.token = null;
        this.captcha.eKey = null;
        this.captcha.expired = true;
        this.captcha.validated = false;
        //console.log('Expired');
      },
      onError(err) {
        this.captcha.token = null;
        this.captcha.eKey = null;
        this.captcha.error = err;
        this.captcha.validated = false;
        //console.log(`Error: ${err}`);
      },
      forceRerender() {
        this.componentKey += 1;
      }
    },
    computed: {
      notEmptyFields() {
        return this.form.name.length > 0 && this.form.email.length > 0 && this.form.message.length > 0;
      },
      language(){
        this.forceRerender()
        return this.$i18n.locale
      }
    }
  }
</script>

<style scoped lang="scss">

input, textarea {
  margin-bottom: 1rem;
}

</style>
