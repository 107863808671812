<template>
  <div id="app">
    <div id="home"></div>
    <div class="d-flex flex-column" id="wrapper">
      <Navbar/>
      <main class="flex-fill">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
      </main>
      <div class="spacer"/>
      <b-navbar id="footer">
        <b-container>
          <b-navbar-nav class="mx-auto">
            <b-nav-item to="/impressum">{{ $t('footer.impressum') }}</b-nav-item>
            <b-nav-item to="/terms-and-conditions" id="footerspace">{{ $t('footer.agb') }}</b-nav-item>
            <b-nav-item to="/privacy">{{ $t('footer.datenschutz') }}</b-nav-item>
          </b-navbar-nav>
        </b-container>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
  components: {
    Navbar
  },
  created() {
    this.queryDates();
    this.getFrontImages();
  },
  mounted(){
    this.cyclicCheck();
  },
  methods: {
    cyclicCheck: async function(){
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      const bool = true
      while(bool){
        await sleep(60000);
        this.queryDates();
      }
    },
    addDays: Date.prototype.addDays = function(days) {
      this.setUTCDate(this.getUTCDate() + parseInt(days));
      return this
    },
    queryDates: function(){
      this.$axios
      .post('/request.php')
      .then(response => { 
        let houserules = response.data.houserules
        this.$store.commit('setHouserules', houserules)
        this.$store.commit('setServiceprice', response.data.serviceprice)
        let dates = response.data.cal;
        let full = [];
        let upper = [];
        let lower = [];

        let getDatesBetweenDates = (startDate, endDate) => {
          let dates = []
          //to avoid modifying the original date
          const theDate = new Date(startDate)
          while (theDate <= endDate) {
            dates.push(new Date(theDate))
            theDate.setDate(theDate.getDate() + 1)
          }
          //console.log(dates)
          return dates
        }

        let toUTCDay = (day) => {
          day.setMinutes(day.getMinutes() - day.getTimezoneOffset())
          let date = new Date();
          date.setUTCFullYear(day.getUTCFullYear())
          date.setUTCMonth(day.getUTCMonth())
          date.setUTCDate(day.getUTCDate())
          date.setUTCHours(0)
          date.setUTCMinutes(0)
          date.setUTCSeconds(0)
          date.setUTCMilliseconds(0)
          return date
        }

        let todayUTC = toUTCDay(new Date());
        //console.log(todayUTC);

        //console.log(dates)

        for (let part in dates){
          //console.log(part)
          let temp = [];
          for (let x of dates[part]){
            //console.log(x)
            if (x != null){
              let from = new Date(x.start);
              let to = new Date(x.end);
              if (to > todayUTC){
                if (from <= todayUTC){
                  from = new Date((todayUTC).addDays(1));
                }
                temp = [...temp, ...getDatesBetweenDates(from, to)];
                //console.log(temp)
              }
            }
          }
          //console.log(temp)
          switch(part){
            case "full":
              full = temp;
              break;
            case "upper":
              upper = temp;
              break;
            case "lower":
              lower = temp;
              break;
          }
        }

        let toBeMoved = [];
        for (let x of upper){
          for (let y of lower){
            if (x.toISOString() === y.toISOString()){
              toBeMoved.push(new Date(x))
            }
          }
        }
        //console.log(toBeMoved)

        full = [...full, ...toBeMoved]
        full.sort((a, b) => a - b)

        let newUpper = []
        let newLower = []

        for (let x of lower){
          let bvar = false
          for (let y of toBeMoved){
            if ((x.toISOString() === y.toISOString())){
              bvar = true
            }
          }
          if (!bvar) {
            newLower.push(new Date(x))
          }
        }

        for (let x of upper){
          let bvar = false
          for (let y of toBeMoved){
            if ((x.toISOString() === y.toISOString())){
              bvar = true
            }
          }
          if (!bvar) {
            newUpper.push(new Date(x))
          }
        }

        let booked = {
          full: full,
          upper: newUpper,
          lower: newLower
        }
        
        this.$store.commit('setBooked', booked)
         
      })
      .catch(error => {
        console.log(error)
      })
    },
    getFrontImages: function(){
      this.$axios
      .post('/getImages.php')
      .then(response => {
        this.$store.commit('setFrontImages', response.data)
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
};
</script>


<style lang="scss">
html, body, #wrapper {
   min-height:100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#footer {
  background-color: #99aab5;
  font-size: 1rem;
  font-weight: 500;
}

#footerspace {
  $navItemMargin: 3rem;
  margin-left: $navItemMargin;
  margin-right: $navItemMargin;
}

.spacer {
  $space: 2rem;
  margin-top: $space;
  margin-bottom: $space;
}

// .slide-enter-active, .slide-leave-active {
//   transition: opacity 1s ease;
// }

.stretch-full {
  width: 100% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// .fade-enter-to,
// .fade-leave-from {
//   opacity: 100%;
// }

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}


.slide-enter-to {
  position: absolute;
  right: 0;
}


.slide-enter-from {
  position: absolute;
  right: -100%;
}


.slide-leave-to {
  position: absolute;
  left: -100%;
}


.slide-leave-from {
  position: absolute;
  left: 0;
}
</style>
