<template>
  <div>
    <b-carousel
      id="carousel-1"
      :interval="5000"
      fade
      no-hover-pause
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
    >
     

      <b-carousel-slide v-for="image in $store.state.frontImages.background" :key="image">
        <h1>{{ $t('start.header') }}</h1>
        <template #img>
          <img
            :src="image" 
            class="img-fluid w-100 d-block"
          >
        </template>
      </b-carousel-slide>
      

    </b-carousel>
  </div>
</template>

<script>

  export default {
    name: 'Start',
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
.img-fluid {
  width: 100% !important;
  height: 100vh !important;
  object-position: center;
  object-fit: cover;
}

h1 {
  text-shadow: 0px 0px 8px #000;
  font-family: 'Droid serif', serif;
  font-style: italic;

    font-size: 2.5rem;


  @media (min-width: 1200px) {
    font-size: 3rem;
  }
  
}
</style>
