import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/home'
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  // {
  //   path: '/booking',
  //   name: 'Booking',
  //   component: () => import(/* webpackChunkName: "booking" */ '../views/Booking.vue')
  // },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "booking" */ '../views/Impressum.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and Conditions',
    component: () => import(/* webpackChunkName: "booking" */ '../views/AGB.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy Statement',
    component: () => import(/* webpackChunkName: "booking" */ '../views/Datenschutz.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
