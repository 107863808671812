<template>
  <b-container>
      <p v-for="(item, index) in $t('about')" :key="index">{{ item }}</p>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">

</style>
