import '@babel/polyfill'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import './plugins/v-calendar'
import './plugins/vuex'
import './plugins/vue2-smooth-scroll'
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from '@/store'
import i18n from './i18n'
import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)

Vue.config.productionTip = false

Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
